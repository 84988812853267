import { NavHashLink as Link } from 'react-router-hash-link'
import arrow from '../down-arrow.svg'

const Hero = () => {
  return (
    <div id='home' className='text-center bg-gray-200 pt-32 pb-5 px-3 md:px-32'>
      <div className='text-4xl font-bold text-secondary-500'>
        Acceso a Internet ilimitado
      </div>
      <div className='text-4xl font-semibold text-primary-500 mb-5'>
        Sin necesidad de cables, sin complicaciones
      </div>
      <div className='text-gray-700'>
        Con wifiExpress tienes acceso a Internet ilimitado, en tu hogar, oficina
        o empresa. Disfruta tu experiencia en Internet con nuestro servicio
      </div>
      <Link to='/#pricing' smooth>
        <img
          src={arrow}
          alt='Go Down'
          style={{ fill: '#95c122' }}
          className='m-auto mt-20 w-16 animate-bounce cursor-pointer'
        />
      </Link>
    </div>
  )
}

export default Hero
