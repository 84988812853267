import Hero from './Hero'
import Pricing from './Pricing'
import About from './About'
import Contact from './Contact'
const Home = () => {
  return (
    <div>
      <Hero />
      <Pricing />
      <About />
      <Contact />
    </div>
  )
}

export default Home
