import { NavHashLink as Link } from 'react-router-hash-link'
import logo from '../logo.svg'
import {AiOutlineInstagram} from 'react-icons/ai'

const Footer = ({ links }) => {
  return (
    <footer className='bg-gray-200 text-center py-10 flex flex-col items-center justify-center gap-3'>
      <img src={logo} className='h-6' alt='WifiExpress Logo' />
      <a href="https://www.instagram.com/wifiexpress.mx/" target='_blank' rel="noopener noreferrer" ><AiOutlineInstagram/></a>
      <div className='flex gap-3'>
        {links.map(link => (
          <Link to={link.to} smooth key={link.name}>
            {link.name}
          </Link>
        ))}
      </div>
     
      <p>
        &copy; {new Date().getFullYear()} WifiExpress.</p><p className='text-xs'>{' '}Legales:{' '}
        <Link to='/aviso-de-privacidad-wifiexpress.pdf' className='underline text-secondary-500' rel="noopener noreferrer" target='_blank'>
          Aviso de privacidad
        </Link>{' '}|{' '}
        <Link to='/cartadederechosdelosusuariosdeserviciosdetelecomunicaciones-wifiexpress.pdf' className='underline text-secondary-500' rel="noopener noreferrer" target='_blank'>
          Carta de derechos del usuario
        </Link>{' '}|{' '}
        <Link to='/codigodepoliticasdegestiondetrafico-wifiexpress.pdf' className='underline text-secondary-500' rel="noopener noreferrer" target='_blank'>
          Código de políticas de gestión de tráfico
        </Link>{' '}|{' '}
        <Link to='/codigodepracticascomerciales-wifiexpress.pdf' className='underline text-secondary-500' rel="noopener noreferrer" target='_blank'>
          Código de prácticas comerciales
        </Link>
      </p>
    </footer>
  )
}

export default Footer
