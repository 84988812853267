import { useState, useEffect } from 'react'
export function useFetch (domain){
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect (() => {
    fetch('https://api.bitmart.com.mx:27034/api/v1/companies/public-profile?domain='+domain, {cache: 'force-cache'})
    .then(response => response.json())
    .then(data => setData(data))
    .catch(error => setError(error))
    },[])
    return {data, error}
}