export const packets = [
  {
    id: 1,
    price: '$350',
    velocity: '5 Mbps'
  },
  {
    id: 2,
    price: '$500',
    velocity: '10 Mbps'
  },
  {
    id: 3,
    price: '$800',
    velocity: '20 Mbps'
  }
]
