export const navlinks = [
  {
    name: 'Precios',
    to: '/#pricing'
  },
  {
    name: 'Acerca',
    to: '/#about'
  },
  {
    name: 'Contacto',
    to: '/#contact'
  }
]
