import { packets } from '../utils/packets'
import { MdChevronLeft, MdChevronRight, MdWifi } from 'react-icons/md'
const Pricing = () => {
  const slideLeft = () => {
    var slider = document.getElementById('slider')
    slider.scrollLeft = slider.scrollLeft - 220
  }

  const slideRight = () => {
    var slider = document.getElementById('slider')
    slider.scrollLeft = slider.scrollLeft + 220
  }
  return (
    <div
      id='pricing'
      className='text-center py-32 px-3 md:px-20 flex flex-col gap-5'
    >
      <h2 className='text-3xl text-primary-500 font-semibold'>
        Internet

      </h2>
      <div className='relative flex items-center'>
        <MdChevronLeft
          className='opacity-50 cursor-pointer hover:opacity-100'
          onClick={slideLeft}
          size={40}
        />
        <div
          id='slider'
          className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'
        >
          {packets.map(item => (
            <div
              key={item.id}
              className='w-[220px] inline-block py-5 border border-primary-500 rounded-md shadow-md mx-3 plan'
            >
              <h2 className='mb-10 text-2xl text-secondary-500 font-semibold'>
                {item.velocity}
              </h2>
              <div>
                <p className='text-gray-600'>DESDE</p>
                <p className='text-2xl font-semibold'>{item.price}</p>
                <p className='mb-10 text-primary-500 font-semibold'>al mes</p>
                <MdWifi size={50} className='mx-auto text-secondary-500' />
              </div>
            </div>
          ))}
        </div>
        <MdChevronRight
          className='opacity-50 cursor-pointer hover:opacity-100'
          onClick={slideRight}
          size={40}
        />
      </div>
    </div>
  )
}

export default Pricing
