import { IoLogoWhatsapp } from 'react-icons/io'
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai'
import { GrFormLocation } from "react-icons/gr";


const Contact = () => {
  return (
    <div id='contact' className='py-32 px-3 md:px-52 flex flex-col gap-2'>
      <h2 className='text-center text-3xl font-semibold text-primary-500'>
        Contacto
      </h2>
      <h3 className='text-center text-xl font-semibold mb-5'>
        Horario de Atención</h3>
        <div className='text-xl font-semibold text-secondary-500 flex flex-col justify-center items-center'>
        <p>Lunes a Viernes de 9:00 a 19:00 horas</p>
        <p>Sábado y domingo de 10:00 a 16:00 horas</p></div>
      
      <div className='flex flex-col md:flex-row gap-5 py-10 items-center justify-center'>
        <div className='w-80 bg-primary-100 flex flex-col items-center rounded-mg p-5 justify-center'>
          <p className='text-xl font-semibold mb-5'>Atención a clientes</p>
          <a
            href='tel:+524456880014'
            className='text-xl font-semibold text-secondary-500 flex flex-col justify-center items-center'
          >
            <AiFillPhone className='text-secondary-500' size={40} />
            <p>+52 445 688 0014</p>
          </a>
        </div>
        <div className='w-80 bg-primary-100 flex flex-col items-center rounded-mg p-5 justify-center'>
          <p className='text-xl font-semibold mb-5'>Correo electrónico  </p>
          <a
            href='mailto:contacto@wifiexpress.mx'
            className='text-xl font-semibold text-secondary-500 flex flex-col justify-center items-center'
          >
            <AiOutlineMail className='text-secondary-500' size={40} />
            <p>contacto@wifiexpress.mx</p>
          </a>
        </div>
        
        <div className='w-80 bg-primary-100 flex flex-col items-center rounded-mg p-5 justify-center'>
          <p className='text-xl font-semibold mb-5'>
            Soporte 24/7 via WhatsApp
          </p>

          <a
            href='https://wa.me/524456880014'
            className='text-xl font-semibold text-secondary-500 flex flex-col justify-center items-center'
          >
            <IoLogoWhatsapp className='text-secondary-500' size={40} />
            <p>+52 445 688 0014</p>
          </a>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center'>
      <GrFormLocation className='text-secondary-500 ' size={40} />
      <p className='text-xl font-semibold text-secondary-500 flex flex-col justify-center items-center'>
        Boulevard Monterrey #68, Col. la Joyita, Uriangato, Guanajuato,
        CP. 38983
      </p></div>
    </div>
  )
}

export default Contact
