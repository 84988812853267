import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Home, Navbar, Footer, PrivacityAdvice } from './components'
import { navlinks } from './utils/navlinks'
function App() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Router>
      <Navbar
        links={navlinks}
        isOpen={isOpen}
        toggleOpen={toggle}
        setIsOpen={setIsOpen}
      />
      <Routes onClick={() => setIsOpen(false)}>
        <Route path='/' element={<Home />} />
        <Route path='/privacity-advice' element={<PrivacityAdvice />} />
      </Routes>
      <Footer links={navlinks} />
    </Router>
  )
}
export default App
