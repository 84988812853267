import { useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll'

const PrivacityAdvice = () => {
  useEffect(() => {
    scroll.scrollToTop()
  }, [])

  return (
    <div className='pt-32 pb-5 px-3 md:px-32'>
      <h2 className='text-primary-500 text-3xl font-semibold mb-10'>
        Aviso de privacidad integral
      </h2>
      <p className='text-lg text-gray-700 mb-5'>
        WifiExpress, con domicilio en
        Boulevard Monterrey #68 Col. la Joyita Uriangato, Guanajuato, México.
        CP. 38983 y portal de internet www.wifiexpress.mx, es el responsable del
        uso y protección de sus datos personales, y al respecto le informamos lo
        siguiente:
      </p>
      <h3 className='text-secondary-500 font-semibold text-xl'>
        ¿Para qué fines utilizaremos sus datos personales?
      </h3>
      <p className='text-lg text-gray-700 mb-5'>
        Los datos personales que recabamos de usted, los utilizaremos para las
        siguientes finalidades que son necesarias para el servicio que solicita:
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        Respuesta a mensajes del formulario de contacto, Prestación de cualquier
        servicio solicitado
      </p>
      <h3 className='text-secondary-500 font-semibold text-xl'>
        ¿Qué datos personales utilizaremos para estos fines?
      </h3>
      <p className='text-lg text-gray-700 mb-5'>
        Para llevar a cabo las finalidades descritas en el presente aviso de
        privacidad, utilizaremos los siguientes datos personales:
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        Datos de identificación y contacto
      </p>
      <h3 className='text-secondary-500 font-semibold text-xl'>
        ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
        oponerse a su uso o ejercer la revocación de consentimiento?
      </h3>
      <p className='text-lg text-gray-700 mb-5'>
        Usted tiene derecho a conocer qué datos personales tenemos de usted,
        para qué los utilizamos y las condiciones del uso que les damos
        (Acceso). Asimismo, es su derecho solicitar la corrección de su
        información personal en caso de que esté desactualizada, sea inexacta o
        incompleta (Rectificación); que la eliminemos de nuestros registros o
        bases de datos cuando considere que la misma no está siendo utilizada
        adecuadamente (Cancelación); así como oponerse al uso de sus datos
        personales para fines específicos (Oposición). Estos derechos se conocen
        como derechos ARCO.
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        Para el ejercicio de cualquiera de los derechos ARCO, debe enviar una
        petición vía correo electrónico a contacto@wifiexpress.mx y deberá contener:
      </p>
      <ul className='mb-5 list-disc ml-10 text-lg text-gray-700'>
        <li>Nombre completo del titular.</li>
        <li>Domicilio.</li>
        <li>Teléfono.</li>
        <li>Correo electrónico usado en este sitio web.</li>
        <li>Copia de una identificación oficial adjunta.</li>
        <li>Asunto «Derechos ARCO»</li>
      </ul>
      <p className='text-lg text-gray-700 mb-5'>
        Descripción el objeto del escrito, los cuales pueden ser de manera
        enunciativa más no limitativa los siguientes: Revocación del
        consentimiento para tratar sus datos personales; y/o Notificación del
        uso indebido del tratamiento de sus datos personales; y/o Ejercitar sus
        Derechos ARCO, con una descripción clara y precisa de los datos a
        Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de Rectificación
        de datos personales, deberá indicar la modificación exacta y anexar la
        documentación soporte; es importante en caso de revocación del
        consentimiento, que tenga en cuenta que no en todos los casos podremos
        atender su solicitud o concluir el uso de forma inmediata, ya que es
        posible que por alguna obligación legal requiramos seguir tratando sus
        datos personales. Asimismo, usted deberá considerar que para ciertos
        fines, la revocación de su consentimiento implicará que no le podamos
        seguir prestando el servicio que nos solicitó, o la conclusión de su
        relación con nosotros.
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        ¿En cuántos días le daremos respuesta a su solicitud?
      </p>
      <p className='text-lg text-gray-700 mb-5'>5 días</p>
      <p className='text-lg text-gray-700 mb-5'>
        ¿Por qué medio le comunicaremos la respuesta a su solicitud?
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        Al mismo correo electrónico de donde se envío la petición.
      </p>
      <h3 className='text-secondary-500 font-semibold text-xl'>
        El uso de tecnologías de rastreo en nuestro portal de internet
      </h3>
      <p className='text-lg text-gray-700 mb-5'>
        Le informamos que en nuestra página de internet utilizamos cookies, web
        beacons u otras tecnologías, a través de las cuales es posible
        monitorear su comportamiento como usuario de internet, así como
        brindarle un mejor servicio y experiencia al navegar en nuestra página.
        Los datos personales que obtenemos de estas tecnologías de rastreo son
        los siguientes:
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        Identificadores, nombre de usuario y contraseñas de sesión
      </p>
      <p className='text-lg text-gray-700 mb-5'> Correo electrónico</p>
      <p className='text-lg text-gray-700 mb-5'>
        Estas cookies, web beacons y otras tecnologías pueden ser
        deshabilitadas. Para conocer cómo hacerlo, consulte el menú de ayuda de
        su navegador. Tenga en cuenta que, en caso de desactivar las cookies, es
        posible que no pueda acceder a ciertas funciones personalizadas en
        nuestro sitio web.
      </p>
      <h3 className='text-secondary-500 font-semibold text-xl'>
        ¿Cómo puede conocer los cambios en este aviso de privacidad?
      </h3>
      <p className='text-lg text-gray-700 mb-5'>
        El presente aviso de privacidad puede sufrir modificaciones, cambios o
        actualizaciones derivadas de nuevos requerimientos legales; de nuestras
        propias necesidades por los productos o servicios que ofrecemos; de
        nuestras prácticas de privacidad; de cambios en nuestro modelo de
        negocio, o por otras causas. Nos comprometemos a mantener actualizado
        este aviso de privacidad sobre los cambios que pueda sufrir y siempre
        podrá consultar las actualizaciones que existan en el sitio web
        www.wifiexpress.mx.
      </p>
      <p className='text-lg text-gray-700 mb-5'>
        Última actualización de este aviso de privacidad: 15/06/2022
      </p>
    </div>
  )
}

export default PrivacityAdvice
