import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import logo from '../logo.svg'
import { NavHashLink as Link } from 'react-router-hash-link'

const Navbar = ({ links, isOpen, toggleOpen, setIsOpen }) => {
  const navigate = useNavigate()
  const scrollToTop = () => {
    scroll.scrollToTop()
    navigate('/')
  }

  return (
    <nav
      style={{
        zIndex: '100'
      }}
      className='bg-white px-2 sm:px-4 py-2.5 shadow-md fixed w-screen'
    >
      <div className='container flex flex-wrap justify-between items-center mx-auto'>
        <div className='flex items-center'>
          <img
            onClick={scrollToTop}
            src={logo}
            className='cursor-pointer mr-3 h-6 sm:h-9'
            alt='WifiExpress Logo'
          />
        </div>
        <button
          type='button'
          className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-md md:hidden hover:bg-gray-200 focus:outline-none'
          onClick={toggleOpen}
        >
          <span className='sr-only'>Open main menu</span>
          <svg
            className='w-6 h-6'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
              clipRule='evenodd'
            ></path>
          </svg>
          <svg
            className='hidden w-6 h-6'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
        <div className={`${!isOpen && 'hidden'} w-full md:block md:w-auto`}>
          <ul className='flex flex-col gap-2 md:gap-0 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium'>
            {links.map(link => (
              <li onClick={() => setIsOpen(false)}>
                <Link
                  key={link.name}
                  to={link.to}
                  smooth
                  className='cursor-pointer rounded-md md:rounded-none  transition-all border-0 md:border-b-2 block py-2 pl-3 md:p-0 hover:bg-primary-500 hover:text-white md:hover:text-black md:hover:bg-transparent hover:border-primary-500 border-white'
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
