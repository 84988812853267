import { useFetch } from "../utils/fetch"
const About = () => {
const { data } = useFetch(window.location.hostname)

  return (
    <div
      id='about'
      className='text-center py-32 px-3 md:px-52 flex flex-col gap-10'
    >
      <h1 className='text-3xl text-primary-500 font-semibold'>
        Acerca de {data?.name}
      </h1>
      <p className='bg-primary-100 rounded-md p-6 text-secondary-500 text-xl font-semibold'>
        WifiExpress es una empresa de telecomunicaciones que busca satisfacer
        las necesidades de productos y servicios de internet, fomentando en cada
        uno de nosotros nuestra filosofía y valores, para asegurar una relación
        permanente con el cliente, nuestros proveedores, accionistas, comunidad
        y medio ambiente. Obteniendo de esta manera una adecuada rentabilidad y
        garantizando nuestra permanencia y crecimiento en el mercado.
      </p>
    </div>
  )
}

export default About
